/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [  
  {
    url: "/",
    name: "Home",
    slug: "home",
    icon: "HomeIcon",
    i18n: "dashboard"
  },
  {
    url: "/park",
    name: "Park",
    slug: "park",
    icon: "MapIcon",
    i18n: "park",
    permission: [ 1, 2 ],
  },
  {
    header: "Management",
    i18n: "management.management",
    // permission: [ 1, 2 ],
    items: [
      {
			url: "/management/logs",
			name: "Logs",
			slug: "logs",
			icon: "HardDriveIcon",
			i18n: "management.logs",
			modulePermission: "MODULE_CATACASH_FIT_LOGS",
      tags: [
        {
          tag: "tags.new",
          tagColor: "#f1c232",
          enabled: true
        },
      ],
      },
      {
        url: "/management/catamoeda",
        name: "Catamoeda",
        slug: "catamoeda",
        icon: "RadioIcon",
        i18n: "management.catamoedaStatus",
        modulePermission: "MODULE_CATAMOEDA_MANAGEMENT",
        tags: [
          {
            tag: "tags.new",
            tagColor: "#f1c232",
            enabled: true
          },
        ],
        },
    ]
  },
  {
    header: "Transaction",
    i18n: "transaction.transactions",
    items: [
      {
        url: "/transaction/deposit",
        name: "Deposit",
        slug: "deposits",
        icon: "ArrowRightIcon",
        i18n: "transaction.depositFlows.deposit",
        tags: [
          {
            tag: "ATUALIZADO!",
            tagColor: "#FF4500",
            enabled: true
          },
        ],
      },
      {
        url: "/transaction/withdrawal",
        name: "Withdrawal",
        slug: "withdrawals",
        icon: "ArrowLeftIcon",
        i18n: "transaction.depositFlows.withdrawal"
      },
      {
        url: "/transaction/voucher",
        name: "Voucher",
        slug: "voucher",
        icon: "ShoppingCartIcon",
        i18n: "transaction.depositFlows.voucher",
      },
      //{
      //  url: "/indicators",
      //  name: "Indicators",
      //  slug: "indicators",
      //  icon: "BarChart2Icon",
      //  i18n: "indicators"
      //}
    ]
  },
  {
    header: "Register",
    i18n: "register",
    items: [
      {
        url: "/users",
        name: "Users",
        slug: "users",
        icon: "UsersIcon",
        i18n: "user.users",
        permission: [ 1, 2 ],
      },
      {
        url: "/company",
        name: "Company",
        slug: "company",
        icon: "BriefcaseIcon",
        i18n: "company.companies",
        permission: [ 1, 2 ],
      },
      {
        url: "/machine",
        name: "Machine",
        slug: "machine",
        icon: "PackageIcon",
        i18n: "machine.machines"
      },
      {
        url: "/group",
        name: "Group",
        slug: "group",
        icon: "UserCheckIcon",
        i18n: "group.groups",
        permission: [ 1, 2 ],
      },
    ]
  }
]

