<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.name">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.name }}</p>
      <small>{{activeUserInfo.photoURL}}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <img v-if="activeUserInfo.picture" key="onlineImg" :src="activeUserInfo.picture" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
        <div v-else class="user-avatar rounded-full shadow-md cursor-pointer block">{{ nameToInitials(activeUserInfo.name).toUpperCase() }}</div>
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/profile').catch(() => {})">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{$t('profile')}}</span>
          </li>

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import axios from "@/axios.js"

export default {
  data() {
    return {

    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    logout() {
        // If JWT login
        if(localStorage.getItem("accessToken")) {
          localStorage.removeItem("accessToken")
          this.$router.push('/pages/login').catch(() => {})
        }

        // Change role on logout. Same value as initialRole of acj.js
        this.$acl.change('admin')
        localStorage.removeItem('userInfo')

        axios.get("/auth/logout", {});
        this.$router.push('/pages/login').catch(() => {})
    },
    nameToInitials(fullName) {
      if(fullName){
        const namesArray = fullName.split(' ')
        if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`
        else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`
      }
    },
    getUserData() {
        this.$vs.loading();
        var self = this;
        var activeUser = this.$store.state.AppActiveUser;
        this.$store.dispatch('httpRequest', {method: 'get', url:`user/${activeUser.id}`}).then((data) => {
            this.$vs.loading.close();

            self.$store.commit("UPDATE_USER_INFO", {
                name: data.name,
                picture: data.picture
            });
        }).catch(() => {
            this.$vs.loading.close();
        });
    },
  },
  beforeMount() {
    this.getUserData();
  }
}
</script>

<style scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  background-color:rgba(var(--vs-primary));
  color:white;
  font-size: 20px;
  line-height: 43px;
}
</style>